import {consola} from 'consola'
import {useCarrierUtils} from './carrier.utils'
import {Logger} from '../../../utils/logger.utils'

export const useCarrier = () => {
  const {getItems} = useDirectusItems()
  const {generateCarrierDto, logger} = useCarrierUtils(inject('globalSettings'))

  const getCarriers = async (
    langCode: string,
    options?: {
      page?: number
      limit?: number
    }
  ) => {
    return getCarrierEntity({
      query: {
        query: {
          filter: {
            status: {
              _eq: 'published'
            }
          }
        },
        sort: ['-date_created'],
        limit: options?.limit || -1
      }
    })
      .then((carriers: any) => {
        if (carriers && Array.isArray(carriers)) {
          return carriers.map((item: any) => generateCarrierDto(item, langCode))
        }
      })
      .catch((e: any) => {
        logger.error('Error: getCarriers: ', e)
        return []
      })
  }

  const getCarrierEntity = (options?: { id?: string | number; query?: any }): Promise<any> => {
    let data = {
      collection: 'carriers',
      params: {
        fields: [
          'id',
          'status',
          'sort',
          'url',
          'show',
          'carrierId',
          'thumbnail.id',
          'thumbnail.title',
          'thumbnail.description',
          'translations.languages_code',
          'translations.title'
        ],
        filter: {
          status: {
            _eq: 'published'
          }
        },
        page: 1,
        limit: -1
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }
    return getItems(data)
  }

  return {
    getCarriers,
    getCarrierEntity,
    logger
  }
}
